const socialButtonsClicks = () => {
  document.addEventListener('click', (e) => {
    const clickedEl = e.target;

    if (clickedEl.hasAttribute('data-share')) {
      e.preventDefault();
      const width = 600;
      const height = 400;
      const url = clickedEl.href;
      const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
      const winTop = window.screenTop ? window.screenTop : window.screenY;
      const x = Math.floor(winLeft + (window.screen.width - width) / 2);
      const y = Math.floor(winTop + (window.screen.height - height) / 2);
      const parentEl = (window.top !== window.self && typeof window.top.document.location.toString() === 'string') ? window.top : window.self; // Check if current window is topmost
      const sOptions = `toolbar=no,scrollbars=yes,location=yes,statusbar=yes,menubar=no,resizable=1,width=${width},height=${height},screenX=${x},screenY=${y}`;
      parentEl.window.open(url, 'social', sOptions);
    }
  });
};

socialButtonsClicks();
