// TEST: https://www.dev.pokernews.com/news/2020/10/galfond-challenge-kornuth-chops-deficit-38117.htm > https://prnt.sc/vuxpec

export default function playerLinks() {
  const allPokerPlayers = document.querySelectorAll('span.pokerPlayer');

  [...allPokerPlayers].forEach((playerElm) => {
    const url = playerElm.getAttribute('data-player-url');

    const name = playerElm.innerText;

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.innerHTML = name;

    playerElm.parentNode.insertBefore(link, playerElm);
    playerElm.remove();
  });
}

playerLinks();
