import onDomReady from '../../utils/onDomReady';

// Matches polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}

// Closest polyfill
if (!Element.prototype.closest) {
  Element.prototype.closest = (s) => {
    let el = this;
    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

const appendGetArg = (url, name, value) => {
  let newUrl = url;
  if (newUrl.indexOf(`${name}=`) === -1) {
    newUrl += `${(url.indexOf('?') > -1 ? '&' : '?') + name}=${encodeURIComponent(value)}`;
  }
  return newUrl;
};

const onDocumentClick = (e) => {
  // Send to Google Analytics "Event Label" = [data-el] attribute values
  const el = e.target.closest('a');
  if (el) {
    const elTracker = e.target.closest('[data-el]');
    if (elTracker && (el.href.indexOf('/ext/') > -1 || el.href.indexOf('/download/') > -1)) {
      let elTrackerDataset = elTracker.dataset.el;
      if (window.pn5558 && elTrackerDataset === 'ColumnBox') {
        elTrackerDataset = 'LR-ColumnBox';
      }
      el.href = appendGetArg(el.href, 'EL', elTrackerDataset);

      const elId = e.target.closest('[data-elid]');
      if (elTrackerDataset === 'Article' && elId) {
        const elIdDataset = elId.dataset.elid;
        el.href = appendGetArg(el.href, 'ELID', elIdDataset);
      }
    }
  }
};

onDomReady(() => {
  document.addEventListener('click', onDocumentClick);
});
