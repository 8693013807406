import onDomReady from '../utils/onDomReady';

const tcApply = () => {
  const cache = {};

  function tcPopupInit() {
    if (!window.tcApplyWin) {
      window.tcApplyWin = window.modalWindow(760, null);
      window.tcApplyWin.fg.classList.add('tcApplyPopup');
    }
    return window.tcApplyWin;
  }

  function isUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  function eventTCApplyClicked(e) {
    e.stopPropagation();
    e.preventDefault();
    if (!window.modalWindow) {
      return;
    }
    const { oid } = e.target.dataset;
    if (isUrl(oid)) {
      window.open(oid, '_blank');
    } else if (oid && window.fetch) {
      const popup = tcPopupInit();
      if (Object.prototype.hasOwnProperty.call(cache, oid)) {
        popup.open(cache[oid]);
      } else {
        popup.loading();
        fetch(`/tcapply.js.php?id=${oid}`)
          .then((resp) => resp.json())
          .then((data) => {
            cache[data.id] = data.html;
            popup.open(data.html);
          })
          .catch((error) => {
            popup.open(error);
          });
      }
    }
  }

  // some rooms inserted as dynamic widgets with javascript anytime,
  // what's why event is attached on "document" and not on button itself
  document.addEventListener('click', (e) => {
    if (!e.target.classList.contains('tc_apply')) {
      return;
    }
    eventTCApplyClicked(e);
  }, false);
};

onDomReady(tcApply);
