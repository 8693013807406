export default function fixedNavbar() {
  const mobileMenuToggler = document.querySelector('.navbar__toggle');
  const isMobileMode = () => window.getComputedStyle(mobileMenuToggler).display !== 'none';

  if (!mobileMenuToggler || isMobileMode()) {
    return false;
  }

  /*
    how it works:
    -------------
    create a scroll event listener
    create function to check scroll position on each scroll event,
    compare curScroll and prevScroll values to find the scroll direction
    scroll up - 1, scroll down - 2, initial - 0
    then set the direction value to curDirection
    compare curDirection and prevDirection
    if it is different, call a function to show or hide the header
    example:
    step 1: user scrolls down: curDirection 2, prevDirection 0 > hide header
    step 2: user scrolls down again: curDirection 2, prevDirection 2 > already hidden, do nothing
    step 3: user scrolls up: curDirection 1, prevDirection 2 > show header
    */

  const doc = document.documentElement;
  const w = window;
  const root = document.body;
  const header = document.querySelector('.navbar');
  const navbarFixed = document.querySelector('.stickyIconNavBar');
  const headerHeight = header.offsetHeight;

  const directionUp = 1;
  const directionDown = 2;

  let curScroll;
  let prevScroll = w.scrollY || doc.scrollTop;
  let curDirection = 0;
  let prevDirection = 0;
  let lastY = 0;

  let toggled;
  const downThreshold = 30;
  const upThreshold = 30;

  const toggleHeader = () => {
    const tocfixed = document.querySelector('.tocFixed');
    toggled = true;
    if (curDirection === directionDown && curScroll - lastY > downThreshold) {
      lastY = curScroll;
      root.classList.remove('navbarSticky');
      if (tocfixed) tocfixed.style.marginTop = 0;
      if (navbarFixed) navbarFixed.style.top = 0;
    } else if (curDirection === directionUp && lastY - curScroll > upThreshold) {
      lastY = curScroll;
      root.classList.add('navbarSticky');
      if (tocfixed) tocfixed.style.marginTop = `${headerHeight}px`;
      if (navbarFixed) navbarFixed.style.top = `${headerHeight}px`;
    } else {
      toggled = false;
    }
    return toggled;
  };

  const checkScroll = () => {
    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) {
      // scrolled down
      curDirection = directionDown;
    } else {
      // scrolled up
      curDirection = directionUp;
    }

    if (curDirection !== prevDirection) {
      toggled = toggleHeader();
    } else {
      lastY = curScroll;
    }

    prevScroll = curScroll;
    if (toggled) {
      prevDirection = curDirection;
    }
  };

  window.addEventListener('scroll', checkScroll);

  return undefined;
}
fixedNavbar();
