export default (callback) => {
  if (
    window.document.readyState === 'complete'
    || (window.document.readyState !== 'loading' && !window.document.documentElement.doScroll)
  ) {
    callback();
  } else {
    window.document.addEventListener('DOMContentLoaded', callback);
  }
};
