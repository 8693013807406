const subdomainsDropdownInit = () => {
  const dropdown = document.getElementById('js-footerSubs');
  if (!dropdown) {
    return;
  }
  const button = dropdown.querySelector('button');
  button.addEventListener('click', () => {
    dropdown.classList.add('on');
  });
  document.addEventListener('click', (e) => {
    const list = document.getElementById('subList');
    if (
      dropdown.classList.contains('on')
        && e.target !== button
        && e.target !== list
        && !list.contains(e.target)
    ) {
      dropdown.classList.remove('on');
      e.preventDefault();
    }
  });
};

subdomainsDropdownInit();

document.addEventListener('turbo:load', () => {
  subdomainsDropdownInit();
});
