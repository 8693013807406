function ukTopListDisclaimer() {
  const roots = document.querySelectorAll('[data-adsdisclosurepopup]');
  if (!roots.length) {
    return;
  }
  [...roots].forEach((item) => {
    const opener = item.querySelector('button.open');
    const closer = item.querySelector('button.close');
    opener.addEventListener('click', () => {
      item.classList.add('active');
    });
    closer.addEventListener('click', () => {
      item.classList.remove('active');
    });
  });
}

ukTopListDisclaimer();

document.addEventListener('turbo:load', () => {
  ukTopListDisclaimer();
});
